import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {

    @Input() cor: string;

    @Output() atualizar$ = new EventEmitter();

    constructor() {
    }

    async ngOnInit() {
    }

    async atualizar() {
        this.atualizar$.emit(this.cor);
    }
}
