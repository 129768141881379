import { NgModule, Injectable } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { EditorComponent } from './editor/editor.component';
import { ProjetosCadastrarComponent } from './projetos/projetos-cadastrar/projetos-cadastrar.component';
import { ProjetosImportarComponent } from './projetos/projetos-importar/projetos-importar.component';
import { ProjetosEditarComponent } from './projetos/projetos-editar/projetos-editar.component';
import { RoteirosCadastrarComponent } from './roteiros/roteiros-cadastrar/roteiros-cadastrar.component';
import { LandingComponent } from './landing/landing.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { SimuladorComponent } from './simulador/simulador.component';
import { LoginComponent } from './login/login.component';
import { EsqueciSenhaComponent } from './login/esqueci-senha/esqueci-senha.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { CadastrarEmpresaComponent } from './empresa/cadastrar-empresa/cadastrar-empresa.component';
import { EditarEmpresaComponent } from './empresa/editar-empresa/editar-empresa.component';
import { EmpresaListaComponent } from './empresa/empresa-lista/empresa-lista.component';
import { ContatoComponent } from './contato/contato.component';
import { ContatoListaComponent } from './contato/contato-lista/contato-lista.component';

import { UsuarioCadastrarComponent } from './usuario/usuario-cadastrar/usuario-cadastrar.component';
import { UsuarioEditarComponent } from './usuario/usuario-editar/usuario-editar.component';
import { UsuarioEditarSenhaComponent } from './usuario/usuario-editar-senha/usuario-editar-senha.component';

import { TecnicoListaComponent } from './tecnico/tecnico-lista/tecnico-lista.component';
import { TecnicoCadastrarComponent } from './tecnico/tecnico-cadastrar/tecnico-cadastrar.component';
import { TecnicoEditarComponent } from './tecnico/tecnico-editar/tecnico-editar.component';
import { TecnicoEditarSenhaComponent } from './tecnico/tecnico-editar-senha/tecnico-editar-senha.component';

import { FontesPageComponent } from './fontes/fontes-page/fontes-page.component';
import { RecursosPageComponent } from './fontes/recursos-page/recursos-page.component';
import { RecursosEditarPageComponent } from './fontes/recursos-editar-page/recursos-editar-page.component';
import { RecursosCadastrarPageComponent } from './fontes/recursos-cadastrar-page/recursos-cadastrar-page.component';

import { ConfirmarDeixarPagina } from '@app/guards/confirmar-saida.guard';
import { AuthGuard } from '@app/guards/auth.guard';
import { ElevationGuard } from '@app/guards/elevation.guard';
import { RestrictedGuard } from '@app/guards/restricted.guard';
import { DownloaderComponent } from './suporte/downloader/downloader.component';


const routes: Routes = [
    { path: '', component: LandingComponent, canActivate: [AuthGuard], data: { name: 'landing'} },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard],  data: { name: 'home' } },
    { path: 'tutorial', component: TutorialComponent, canActivate: [AuthGuard],  data: { name: 'tutorial' } },
    { path: 'editor', component: EditorComponent, canActivate: [AuthGuard],  canDeactivate: [ConfirmarDeixarPagina], data: { name: 'editor' }},

    { path: 'projetos/cadastrar', component: ProjetosCadastrarComponent, canActivate: [AuthGuard],  canDeactivate: [ConfirmarDeixarPagina], data: { name: 'projeto' } },
    { path: 'projetos/importar', component: ProjetosImportarComponent, canActivate: [AuthGuard],  canDeactivate: [ConfirmarDeixarPagina], data: { name: 'projeto' } },
    { path: 'projetos/editar/:id', component: ProjetosEditarComponent, canActivate: [AuthGuard],  canDeactivate: [ConfirmarDeixarPagina], data: { name: 'projeto' } },
    { path: 'projetos/:id/roteiros', component: RoteirosCadastrarComponent, canActivate: [AuthGuard],  canDeactivate: [ConfirmarDeixarPagina], data: { name: 'roteiros' } },
    { path: 'projetos/:id/simulador', component: SimuladorComponent, canActivate: [AuthGuard],  data: { name: 'simulador' } },

    { path: 'contato', component: ContatoComponent, canActivate: [AuthGuard], data: { name: 'contato' } },
    { path: 'contato/listar', component: ContatoListaComponent, canActivate: [AuthGuard, RestrictedGuard], data: { name: 'contato' } },

    { path: 'login', component: LoginComponent, data: { name: 'login' } },
    { path: 'login/esqueci-minha-senha', component: EsqueciSenhaComponent, data: { name: 'login' } },
    { path: 'usuarios/cadastrar', component: UsuarioCadastrarComponent, canActivate: [AuthGuard, ElevationGuard], data: { name: 'usuario' } },
    { path: 'usuarios/senha', component: UsuarioEditarSenhaComponent, canActivate: [AuthGuard], data: { name: 'usuario' } },
    { path: 'usuarios/:id/editar', component: UsuarioEditarComponent, canActivate: [AuthGuard, ElevationGuard], data: { name: 'usuario' } },

    { path: 'tecnicos', component: TecnicoListaComponent, canActivate: [AuthGuard, RestrictedGuard],  data: { name: 'tecnico' } },
    { path: 'tecnicos/cadastrar', component: TecnicoCadastrarComponent, canActivate: [AuthGuard, RestrictedGuard], data: { name: 'tecnico' } },
    { path: 'tecnicos/senha', component: TecnicoEditarSenhaComponent, canActivate: [AuthGuard], data: { name: 'tecnico' } },
    { path: 'tecnicos/:id/editar', component: TecnicoEditarComponent, canActivate: [AuthGuard, RestrictedGuard], data: { name: 'tecnico' } },

    { path: 'fontes', component: FontesPageComponent, canActivate: [AuthGuard, RestrictedGuard],  data: { name: 'fontes' } },
    { path: 'recursos', component: RecursosPageComponent, canActivate: [AuthGuard],  data: { name: 'fontes' } },
    { path: 'recursos/cadastrar', component: RecursosCadastrarPageComponent, canActivate: [AuthGuard], data: { name: 'fontes' } },
    { path: 'recursos/:id/editar', component: RecursosEditarPageComponent, canActivate: [AuthGuard], data: { name: 'fontes' } },
    { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuard],  data: { name: 'empresa' } },
    { path: 'empresa/cadastrar', component: CadastrarEmpresaComponent, canActivate: [AuthGuard, RestrictedGuard],  data: { name: 'empresa' } },
    { path: 'empresa/:id/editar', component: EditarEmpresaComponent, canActivate: [AuthGuard, RestrictedGuard],  data: { name: 'empresa' } },
    { path: 'empresa/listar', component: EmpresaListaComponent, canActivate: [AuthGuard, RestrictedGuard],  data: { name: 'empresa' } },

    { path: 'suporte', component: DownloaderComponent, canActivate: [AuthGuard, RestrictedGuard],  data: { name: 'suporte' } },

    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [ RouterModule ]
})

export class RoutingModule { }
