<div class="mensagem-header"> <ng-container i18n>Mensagem</ng-container> {{i + 1}}
    <span (click)="toggleEditar()" [attr.tooltip]="tooltips.editar">
        <i class="size-1 icon clickable">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-time.svg#icon"></use>
            </svg>
        </i>
    </span>
    <span (click)="duplicar()" [attr.tooltip]="tooltips.duplicar">
        <i class="size-1 icon clickable">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-duplicate.svg#icon"></use>
            </svg>
        </i>
    </span>
    <span class="message-delete-button" (click)="excluir()" [attr.tooltip]="tooltips.excluir">
        <i class="size-1 clickable icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-delete.svg#icon"></use>
            </svg>
        </i>
    </span>
</div>

<div *ngIf="editando()" class="controles">
    <div class="controles-interno">
        <span i18n>Tempo:</span>
        <button class="btn" (click)="diminuirTempo()">
            <i class="size-1 clickable icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-arrow-left.svg#icon"></use>
                </svg>
            </i>
        </button>
        <span>{{mensagem.tempo}} &nbsp;</span>
        <button class="btn" (click)="aumentarTempo()">
            <i class="size-1 clickable icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-arrow-right.svg#icon"></use>
                </svg>
            </i>
        </button>
    </div>
    <div class="controles-interno">
        <span i18n>Ordem:</span>
        <button class="btn" (click)="diminuirOrdenacao()">
            <i class="size-1 clickable icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-arrow-up.svg#icon"></use>
                </svg>
            </i>
        </button>
        <span>{{mensagem.ordem}} &nbsp;</span>
        <button class="btn" (click)="aumentarOrdenacao()">
            <i class="size-1 clickable icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-arrow-down.svg#icon"></use>
                </svg>
            </i>
        </button>
    </div>
</div>

<div class="mensagem-content">

    <!-- editor -->
    <app-editor-form-floating *ngIf="grupoService.grupo != undefined && mensagem.grupos != undefined && mensagem.grupos.indexOf(grupoService.grupo) != -1"
        [grupo]="grupoService.grupo" [gruposRef]="mensagem.grupos" [painel]="painel"
        (atualizar)="render()" (excluir)="excluirGrupo($event)" (replicar)="replicar($event)" #form>
    </app-editor-form-floating>

    <!-- view -->
    <app-editor-view
      [selected]="grupoService.grupo != undefined && mensagem.grupos != undefined && mensagem.grupos.indexOf(grupoService.grupo) != -1"
      [painel]="painel"
      [mensagem]="mensagem"
      [rects]="true"
      (editar)="editarGrupo($event)"
      (click)="clickListener($event)"
      #view></app-editor-view>
</div>
<button class="btn btn-primary new-group-button" (click)="novoGrupo()" [attr.tooltip]="tooltips.adicionar" i18n>
    Novo grupo
</button>
