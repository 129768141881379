import { Injectable } from '@angular/core';
import { VariaveisApiService } from '@app/services/variaveis-api.service';
import * as model from '../projeto.model';

// import { DesenhosApiService } from '@app/services/desenhos-api.service';
import { FontCalculator } from './font-calculator';

@Injectable({
    providedIn: 'root'
})
export class ChangeCalculator {

    constructor(
        private fontCalculator: FontCalculator,
        // private desenhosApi: DesenhosApiService,
        private variaveisApi: VariaveisApiService,
    ) {}

    async calculate(mensagem: model.Mensagem, pOrigem: model.Painel, pDestino: model.Painel): Promise<model.Mensagem> {
        const propX = +pDestino.dimension_x / +pOrigem.dimension_x;
        const propY = +pDestino.dimension_y / +pOrigem.dimension_y;


        const msgRet = {
            'id': mensagem.id,
            'rstr': mensagem.rstr,
            'ordem': mensagem.ordem,
            'tempo': mensagem.tempo,
            'descricao': mensagem.descricao,
            'idPainel': pDestino.id,
            'idRoteiro': mensagem.idRoteiro,
            'grupos': [],
        };

        for (let grupo of mensagem.grupos) {
            let start_x = 0;
            let start_y = 0;
            let end_x = 0;
            let end_y = 0;

            if (propY <= 1) {
                start_y = Math.ceil(+grupo.start_y * propY);
                end_y = Math.round(+grupo.end_y * propY) >= pDestino.dimension_y ? pDestino.dimension_y - 1 : Math.round(+grupo.end_y * propY);
            } else {
                start_y = Math.ceil(+grupo.start_y * propY);
                end_y = Math.ceil(+grupo.end_y * propY) >= pDestino.dimension_y ? pDestino.dimension_y - 1 : Math.ceil(+grupo.end_y * propY);
            }

            if (end_y < start_y) {
                end_y = start_y;
            }

            if (propX <= 1) {
                start_x = Math.ceil(+grupo.start_x * propX);
                end_x = Math.floor(+grupo.end_x * propX) >= pDestino.dimension_x ? pDestino.dimension_x - 1 : Math.floor(+grupo.end_x * propX);
            } else {
                start_x = Math.ceil(+grupo.start_x * propX);
                end_x = Math.ceil(+grupo.end_x * propX) >= pDestino.dimension_x ? pDestino.dimension_x - 1 : Math.ceil(+grupo.end_x * propX);
            }

            if (end_x < start_x) {
                end_x = start_x;
            }

            // verificar se não existe outro no mesmo lugar
            let sobreposto = false;
            for (let g of msgRet.grupos) {
                if (
                    g.start_x >= start_x && g.start_x <= end_x && g.start_y >= start_y && g.start_y <= end_y || // testando o ponto A
                    g.end_x >= start_x && g.end_x <= end_x && g.start_y >= start_y && g.start_y <= end_y || // testando o ponto B
                    g.end_x >= start_x && g.end_x <= end_x && g.end_y >= start_y && g.end_y <= end_y || // testando o ponto C
                    g.start_x >= start_x && g.start_x <= end_x && g.end_y >= start_y && g.end_y <= end_y || // testando o ponto C
                    // precisamos testar o caso de que um retangulo esta dentro do outro
                    g.start_x >= start_x && g.end_x <= end_x && g.start_y <= start_y && g.end_y >= start_y
                ) {
                    sobreposto = true;
                }
            }

            if (!sobreposto) {
                let idOrigem = grupo.idOrigem;

                // Caso o grupo não for redimensionado, não é necessário alterar a fonte
                if (propX != 1 || propY != 1) {
                    const fonte = await this.fontCalculator.calcularMelhorFonte(end_y - start_y + 1);
                    if (grupo.origem == 1) { // FONTE
                        idOrigem = fonte.id;
                    }
                    else if (grupo.origem == 3) { // VARIÁVEL
                        const variaveis = await this.variaveisApi.list_all();
                        const idFonteTamanho = fonte.id;

                        const atual = variaveis.find((variavel: model.Variavel) => variavel.id == grupo.idOrigem);

                        const novo = variaveis.find((variavel: model.Variavel) => variavel.tipoVariavel == atual.tipoVariavel && variavel.idFonte == idFonteTamanho);

                        idOrigem = novo.id;
                    }
                }

                msgRet.grupos.push({
                    'id': grupo.id,
                    'rstr': grupo.rstr,
                    'content': grupo.content,
                    'spacing': grupo.spacing,
                    'invertido': grupo.invertido,
                    'autoSize': grupo.autoSize,
                    'start_x': start_x,
                    'start_y': start_y,
                    'end_x': end_x,
                    'end_y': end_y,
                    'offsetX': 0,
                    'offsetY': 0,
                    'efeito': grupo.efeito,
                    'origem': grupo.origem,
                    'idOrigem': idOrigem,
                    'cor': grupo.cor
                });
            }
        }

        return msgRet;
    }
}
