import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as model from '@app/projeto.model';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
    
    @Input() cor: string;

    @Output() atualizar$ = new EventEmitter();

    constructor() {
    }

    async ngOnInit() {
    }

    async atualizar(event){
        this.atualizar$.emit(event.target.value);
    }

}
