<ng-container *ngIf="roteiros != undefined">

    <h1 i18n>Cadastro de roteiros</h1>
    <section class="mb">
        <button id="aba-roteiros" class="chip chip-outline" [class.chip-selected]="tipoExibido == 'roteiro'" (click)="trocaTipo('roteiro')" [disabled]="!roteirosForm.form.valid && tipoExibido == 'extra'" i18n>Roteiros</button>
        <button id="aba-extras" class="chip chip-outline" [class.chip-selected]="tipoExibido == 'extra'" (click)="trocaTipo('extra')" [disabled]="!roteirosForm.form.valid && tipoExibido == 'roteiro'" i18n>Extras</button>
    </section>
    <section class="search-section form-section" *ngIf="roteiros.length > 0">
        <label for="pesquisa" i18n>Pesquisar</label>
        <input type="text" name="pesquisa" [(ngModel)]="pesquisa" placeholder="Digite para pesquisar" i18n-placeholder>
    </section>
    <form #roteirosForm="ngForm">
        <div class="container list-labels">
            <div class="buttons-stadin"></div>
            <p class="name-label" i18n>Nome</p>
            <p class="code-label" i18n>Código</p>
            <p class="code-label" *ngIf="tipoExibido == 'roteiro'" i18n>Retorno</p>
            <p class="desc-label" i18n>Descrição</p>
        </div>
        <ul class="container">
            <li class="roteiro form-section fade" *ngFor="let roteiro of roteiros; let i = index; trackBy: track"
                [class.hidden]="getRoteiros().indexOf(roteiro) == -1"  [class.movendo]="roteiro == movendo">

                <!-- Botão para mover o roteiro antes do item -->
                <button class="move-container" *ngIf="movendo && +movendo.ordem > +roteiro.ordem" (click)="mover_finalizar(ordem_anterior(roteiro))">
                    <div [attr.tooltip]="tooltips.move" class="move-icon">
                        <i class="fas fa-indent"></i>
                    </div>
                </button>

                <app-roteiros-form [roteiro]="roteiro" [outrosRoteiros]="roteiros" [i]="i" [movendo]="movendo" (mover)="mover_iniciar(roteiro)" (duplicar)="duplicar(roteiro)" (excluir)="excluir(roteiro)">
                </app-roteiros-form>

                <!-- Botão para mover o roteiro para depois desse item -->
                <button class="move-container" *ngIf="movendo && +movendo.ordem < +roteiro.ordem" (click)="mover_finalizar(ordem_seguinte(roteiro))">
                    <div [attr.tooltip]="tooltips.move" class="move-icon">
                        <i class="fas fa-indent"></i>
                    </div>
                </button>

            </li>
        </ul>
        <div class="alert warning" *ngIf="roteiros.length == 0" i18n>Você não possui nenhum roteiro cadastrado.</div>

        <div class="form-section">
            <button id="botao-novo-roteiro" class="btn btn-primary" type="button" name="button" (click)="novo()" [disabled]="roteiros.length > 9999" *ngIf="tipoExibido == 'roteiro'" i18n>Novo roteiro</button>
            <button id="botao-novo-extra" class="btn btn-primary" type="button" name="button" (click)="novoExtra()" [disabled]="roteiros.length > 9999" *ngIf="tipoExibido == 'extra'" i18n>Novo extra</button>
        </div>
        <div class="form-section">
            <button id="destinations-save-button" class="btn btn-success" type="submit" name="button" (click)="salvar()" [disabled]="!roteirosForm.form.valid" i18n>Salvar roteiros</button>
        </div>
    </form>
</ng-container>
