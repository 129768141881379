<h1>
    <ng-container i18n>Roteiro</ng-container> {{roteiro.cod}}: {{roteiro.nome}} &nbsp;
    <button class="btn btn-outline" type="button" name="button" (click)="anteriorRoteiro()" i18n>Roteiro anterior</button>
    <button class="btn btn-outline" type="button" name="button" (click)="proximoRoteiro()" i18n>Próximo roteiro</button>
    <button class="btn btn-outline" type="button" name="button" (click)="novoRoteiro()" i18n>Novo roteiro</button>
</h1>
<div class="mb">
    <ng-container *ngFor="let painelIt of paineis; let i = index; trackBy: trackPainel">
        <button class="chip chip-outline" [class.chip-selected]="painelIt.id == painel.id"
            [class.warning]="chipWarning(painelIt)" type="button" name="button" (click)="trocarPainel(painelIt)" i18n>
            Painel {{painelIt.dimension_y}} x {{painelIt.dimension_x}} - {{painelIt.localizacao}}
        </button>
    </ng-container>
    <button class="btn btn-success" type="button" name="button" (click)="replicarConteudo()" i18n>Replicar para outros paineis</button>
</div>

<button class="btn btn-outline" type="button" name="button" (click)="abrirAtalhos()" i18n>Atalhos</button>
<app-editor-atalhos-modal [shortcuts]="shortcuter.shortcuts" (fechar)="fecharAtalhos()"
    [hidden]="!exibirModalAtalho"></app-editor-atalhos-modal>

<ng-container *ngIf="roteiro != undefined && ordenarMensagens() != undefined">
    <div #mensagemContainer *ngFor="let mensagem of ordenarMensagens(); let i = index;" class="mensagem" tabindex="1">
        <app-editor-mensagem #editor
            [i]="i" [mensagem]="mensagem" [painel]="painel"
            (duplicar$)="duplicar($event)" (excluir$)="excluir($event)" (replicar$)="replicar($event)">
        </app-editor-mensagem>
    </div>
</ng-container>

<div class="alert warning" *ngIf="listarMensagens() != undefined && listarMensagens().length == 0" i18n>
    Você não possui nenhuma mensagem cadastrada.<br>
    Cadastre uma para começar a edição.
</div>


<button id="botao-nova-mensagem" class="btn btn-primary" type="button" name="button" (click)="novoTemplate()" [disabled]="listarMensagens() == undefined || listarMensagens().length >= 10" i18n>Nova mensagem</button>

<app-editor-templates-modal [painel]="painel" (fechar)="desistirTemplate()" (grupos)="adicionarDeTemplate($event)" [hidden]="!exibirModalTemplate"></app-editor-templates-modal>
<div class="form-section">
    <button id="botao-salvar-projeto" class="btn btn-success" type="button" name="button" (click)="salvar()" i18n>Salvar projeto</button>
</div>
<div class="form-section">
</div>
