import { Component, OnInit, Input, Output, EventEmitter, OnChanges, HostListener } from '@angular/core';
// import { DesenhosApiService } from '@app/services/desenhos-api.service';
import { FontesApiService } from '@app/services/fontes-api.service';
import { TranslateResourcesService } from '@app/services/translate-resources.service';
import { PositionCalculator } from '@app/editor/position-calculator';
import { FontCalculator } from '@app/editor/font-calculator';
import { ShortcutsService } from '@app/shortcuts.service';
import { ToastrService } from 'ngx-toastr';
import * as model from '@app/projeto.model';

import { RecursosApiService } from '@app/services/recursos-api.service';
import { VariaveisApiService } from '@app/services/variaveis-api.service';
import { DesenhosApiService } from '@app/services/desenhos-api.service';

@Component({
    selector: 'app-editor-form-floating',
    templateUrl: './editor-form-floating.component.html',
    styleUrls: ['./editor-form-floating.component.scss']
})
export class EditorFormFloatingComponent implements OnInit, OnChanges {

    @Input() grupo: model.Grupo;
    @Input() gruposRef: model.Grupo[]; // outros grupos da mensagem, para usar nos cálculos de justificar
    @Input() painel: model.Painel;

    @Output() atualizar = new EventEmitter();
    @Output() excluir = new EventEmitter<model.Grupo>();
    @Output() replicar = new EventEmitter<model.Grupo>();

    variaveis: model.Variavel[] = [];
    fontes: model.Fonte[] = [];
    recursos: model.Recurso[] = [];

    private toasting = false;

    public tooltips = {
        'efeito': $localize `Efeito`,
        'tipo': $localize `Tipo de desenho`,
        'fonte': $localize `Fonte`,
        'cor': $localize `Cor`,
        'inverter': $localize `Inverter`,
        'diminuirEspacamento': $localize `Diminuir o espaçamento`,
        'aumentarEspacamento': $localize `Aumentar o espaçamento`,
        'espacamento': $localize `Espaçamento atual`,
        'recurso': $localize `Recurso`,
        'moverEsquerda': $localize `Mover para a esquerda`,
        'moverDireita': $localize `Mover para a direita`,
        'moverBaixo': $localize `Mover para baixo`,
        'moverCima': $localize `Mover para cima`,
        'alinharEsquerda': $localize `Alinhar à esquerda`,
        'alinharBaixo': $localize `Alinhar abaixo`,
        'alinharCima': $localize `Alinhar acima`,
        'alinharDireita': $localize `Alinhar à direita`,
        'justificarVertical': $localize `Centralizar verticalmente`,
        'justificarHorizontal': $localize `Centralizar horizontalmente`,
        'autoSize': $localize `Habilitar Auto Size`,
        'aumentarGrupo': $localize `Aumentar as dimensões do grupo`,
        'diminuirGrupo': $localize `Diminuir as dimensões do grupo`,
        'aumentarAltura': $localize `Aumentar a altura do grupo`,
        'diminuirAltura': $localize `Diminuir a altura do grupo`,
        'replicar': $localize `Replicar para outras mensagens`,
        'excluir': $localize `Excluir grupo`,
        'aumentarOffsetVertical': $localize `Aumentar o espaçamento vertical`,
        'diminuirOffsetVertical': $localize `Diminuir o espaçamento vertical`,
        'aumentarOffsetHorizontal': $localize `Aumentar o espaçamento horizontal`,
        'diminuirOffsetHorizontal': $localize `Diminuir o espaçamento horizontal`,
        // 'efeito': 'Efeito',
    };

    private variablesCharacters = [
        //Clock
        {possibleCharacters: ":0123456789-", variableId: 1},
        //Temperature
        {possibleCharacters: "-0123456789°C?", variableId: 2},
        //Speed
        {possibleCharacters: "0123456789km/h?", variableId: 3},
        //BoxNumber
        {possibleCharacters: "0123456789?", variableId: 4},
        //Date
        {possibleCharacters: "-0123456789/", variableId: 5},
        //DepartureTime
        {possibleCharacters: "-:0123456789", variableId: 6},
        //ArrivalTime
        {possibleCharacters: "-:0123456789", variableId: 7}
    ];


    @HostListener('document:keydown', ['$event'])
    keyEventDown(event: KeyboardEvent) {
        const shortcut = this.shortcuter.shortcuts.find((s) => s.key == event.key && s.alt == event.altKey && s.shift == event.shiftKey);
        if (shortcut) {
            this[shortcut.func]();
            event.stopPropagation();
            event.preventDefault();
        }
    }

    constructor(
        public desenhosApi: DesenhosApiService,
        public fontesApi: FontesApiService,
        public recursosApi: RecursosApiService,
        public variavelApi: VariaveisApiService,
        private calculator: PositionCalculator,
        private fontCalculator: FontCalculator,
        private translateRes: TranslateResourcesService,
        private toastr: ToastrService,
        private shortcuter: ShortcutsService
    ) { }

    async ngOnInit() {
        let variaveis$ = this.variavelApi.list_all();
        let fontes$ = this.fontesApi.list_all();
        let recursos$ = this.recursosApi.list_all();

        this.variaveis = await variaveis$;
        this.fontes = await fontes$;
        this.recursos = await recursos$;
    }

    async ngOnChanges() {
        await this.shouldAutoSize();
        this.atualizar.emit();
    }

    /**
     * Do stuff according to pressed key
     * @param  event pressed key
     */
    async triggerKeyPress(event: KeyboardEvent) {
        event.stopPropagation();
        event.preventDefault();

        if (event.key !== 'Enter' && event.key !== 'Delete') {
            this.appendText(event.key);
        }
    }

    async appendText(text: string) {
        if (this.grupo.content.length >= 405)
            return;

        for (let ch of text) {
            // verificar se o caractere existe na fonte do grupo atual
            if (this.grupo.origem == 1 && ch != " " && ch != "\n") {
                const fontes = await this.fontesApi.list_all();
                const fonte_atual = fontes.find((fonte) => fonte.id == this.grupo.idOrigem);

                const caractere = fonte_atual.letras.find(letra => letra.caractere == ch);

                if (!caractere) {
                    this.toastr.warning($localize
                        `Caractere "${ch}" não encontrado na fonte selecionada`);
                    return;
                }
            }
        }

        this.grupo.content += text;
        await this.shouldAutoSize();
        this.atualizar.emit();
    }

    async triggerKeyDown(event: KeyboardEvent) {
        if (event.key === 'Backspace') {
            this.grupo.content = this.grupo.content.slice(0, -1);

            await this.shouldAutoSize();

            this.atualizar.emit();
            return false;
        }
        return true;
    }

    async definir_efeito(efeito: number) {
        this.grupo.efeito = efeito;
        this.alterar();
    }

    async alterar() {
        if (this.grupo.efeito != undefined)
            this.grupo.efeito = +this.grupo.efeito;
        await this.shouldAutoSize();
        this.atualizar.emit();
    }

    alterarCor(event: string) {
        this.grupo.cor = event;
        this.atualizar.emit();
    }

    alterarCorFundo(event: string) {
        this.grupo.corFundo = event;
        this.atualizar.emit();
    }

    possuiRolagem() {
        return this.gruposRef.find((grupo: model.Grupo) => grupo.efeito == 2 && grupo != this.grupo) !== undefined;
    }

    infoVariavel(idVariavel: number): model.Variavel {
        return this.variaveis.find((variavel: model.Variavel) => variavel.id == idVariavel);
    }

    excluirGrupo() {
        this.excluir.emit(this.grupo);
    }

    async shouldAutoSize() {
        if (this.grupo.autoSize) {

            let
                start_x = this.grupo.start_x,
                start_y = this.grupo.start_y,
                end = await this.translateRes.getAutoSize(this.grupo),
                end_x = end[0],
                end_y = end[1];

            this.setBounds(start_x, start_y, end_x, end_y);

            // se não tem mais nenhum ponto, e o autosize tá ligado...
            let translated = await this.translateRes.translate(this.grupo);
            if ([...translated].length === 0) {
                // faz a linha comprida do campo vazio
                const tamanho = +await this.desenhosApi.getTamanho(this.grupo.origem, this.grupo.idOrigem);
                this.setBounds(this.grupo.start_x, this.grupo.start_y, this.grupo.end_x,
                    +this.grupo.start_y + +tamanho - 1
                );
            }
        }
    }

    async alterarTipo() {
        this.grupo.origem = +this.grupo.origem;

        const tamanho = (+this.grupo.end_y - +this.grupo.start_y) > 0 ? (+this.grupo.end_y - +this.grupo.start_y + 1) : this.painel.dimension_y;
        const recursos = await this.recursosApi.list_all();
        if (+this.grupo.origem === 1) { // Fonte
            const fonte = await this.fontCalculator.calcularMelhorFonte(tamanho);
            this.grupo.idOrigem = +fonte.id;
        } else if (+this.grupo.origem === 2 && recursos.length > 0) { // Recurso
            this.grupo.idOrigem = +recursos[0].id;
        } else if (+this.grupo.origem === 3) { // Variável
            const fonte = await this.fontCalculator.calcularMelhorFonte(tamanho);
            const idFonte = fonte.id;
            const variaveis = await this.variavelApi.list_all();

            this.grupo.idOrigem = +variaveis
                .find((variavel: model.Variavel) => variavel.tipoVariavel == 1 && +variavel.idFonte == +idFonte).id;
        }

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async alterarFonte(idFonte: number) {
        const fontes = await this.fontesApi.list_all();
        this.grupo.idOrigem = +fontes
            .find((fonte) => fonte.id == idFonte)
            .id;
        await this.shouldAutoSize();
        this.atualizar.emit();
    }

    async alterarVariavel(tipoVariavel: number, idFonte: number) {
        const variaveis = await this.variavelApi.list_all();
        this.grupo.idOrigem = +variaveis
            .find((variavel: model.Variavel) =>
                variavel.tipoVariavel == tipoVariavel &&
                variavel.idFonte == idFonte
            )
            .id;
        await this.shouldAutoSize();
        this.atualizar.emit();
    }

    async alterarRecurso(idRecurso: number) {
        const recursos = await this.recursosApi.list_all();
        this.grupo.idOrigem = +recursos
            .find((recurso) => recurso.id == idRecurso)
            .id;
        await this.shouldAutoSize();
        this.atualizar.emit();
    }

    async aumentarEspacamento() {
        if (this.grupo.spacing >= 32) {
            return;
        }
        this.grupo.spacing++;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async diminuirEspacamento() {
        if (this.grupo.spacing <= -32) {
            return;
        }
        this.grupo.spacing--;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async aumentarOffsetY() {
        if (this.grupo.offsetY >= (this.grupo.end_y - this.grupo.start_y)) {
            return;
        }
        this.grupo.offsetY++;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async diminuirOffsetY() {
        if (this.grupo.offsetY <= 0) {
            return;
        }
        this.grupo.offsetY--;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async aumentarOffsetX() {
        if (this.grupo.offsetX >= (this.grupo.end_x - this.grupo.start_x)) {
            return;
        }
        this.grupo.offsetX++;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async diminuirOffsetX() {
        if (this.grupo.offsetX <= 0) {
            return;
        }
        this.grupo.offsetX--;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    // função que altera as boundaries do grupo
    // faz a validação
    setBounds(start_x: number, start_y: number, end_x: number, end_y: number) {

        const boxes = this.calculator.calcBoxes(this.gruposRef)
            .filter((box) => !(+box[0] === +this.grupo.start_x && +box[1] === +this.grupo.end_x && +box[2] === +this.grupo.start_y && +box[3] === +this.grupo.end_y));

        // let fimX = +end_x;
        // let fimY = +end_y;
        // let encontrouX = false;
        // let encontrouY = false;

        for (let j = +start_y; j <= +end_y; j++) {
            for (let i = +start_x; i <= +end_x; i++) {
                if (!this.calculator.estaLivre(boxes, i, j)) {
                    if (!this.toasting) {
                        setTimeout(() => {
                            this.toasting = true;
                            const toast = this.toastr.error(
                                $localize `A sua ação conflitava com o espaço de outro grupo.\nVocê pode mudar as dimensões manualmente desabilitando o AutoSize.`,
                                $localize `Não foi possível realizar a ação`,
                                {
                                    'enableHtml': true,
                                    'timeOut': 5000,
                                }
                            );
                            toast.onHidden.subscribe(() => this.toasting = false);
                        }, 0);
                    }



                    return;
                    // console.log('loop ' + j + '-' + i);
                    // console.log('j ' + j);
                    // console.log('i ' + i);
                    // console.log('encontrouX ' + encontrouX);
                    // console.log('encontrouY ' + encontrouY);
                    //
                    // if (!encontrouX || !encontrouY) {
                    //     if (!encontrouY && j < (+start_y + +end_y) / 2 && j < fimY) {
                    //         console.log('encontrou Y fimY antigo ' + fimY);
                    //         encontrouY = true;
                    //         fimY = j - 1;
                    //     } else if (!encontrouX && i < fimX) {
                    //         console.log('encontrou X fimX antigo ' + fimX);
                    //         encontrouX = true;
                    //         fimX = i - 1;
                    //     }
                    // } else {
                    //     break;
                    // }


                    // if (!encontrou && j >= (+start_y + +end_y) / 2) {
                    //     // se passar da metade da altura, usamos fimX
                    //     encontrou = true;
                    //     fimX = i - 1;
                    //     fimY = +end_y;
                    // } else if (!encontrou) {
                    //     // não havíamos encontrado, então não passou da metade da altura
                    //     // nesse caso, usamos o fimY
                    //     encontrou = true;
                    //     fimX = +end_x;
                    // }
                    // start_x = this.grupo.start_x;
                    // end_x = i - 1;
                    // break;
                }
            }
        }

        // end_x = fimX;
        // end_y = fimY;


        if (+start_x < 0 || +end_x < +start_x) {
            return;
        } else if (+end_x < +this.painel.dimension_x) {
            this.grupo.start_x = start_x;
            this.grupo.end_x = end_x;
        } else {
            this.grupo.end_x = +this.painel.dimension_x - 1;
        }

        if (+start_y < 0 || +end_y < +start_y) {
            return;
        } else if (+end_y < +this.painel.dimension_y) {
            this.grupo.start_y = start_y;
            this.grupo.end_y = end_y;
        } else {
            this.grupo.end_y = +this.painel.dimension_y - 1;
        }
    }


    async toggleAutoSize() {
        this.grupo.autoSize = !this.grupo.autoSize;
        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    toggleInvertido() {
        this.grupo.invertido = !this.grupo.invertido;
        this.atualizar.emit();
    }

    toggleCustomizavel() {
        this.grupo.useColors = !this.grupo.useColors;
        if(!this.grupo.useColors) {
            this.grupo.cor = '#f2d21f';
            this.grupo.corFundo = undefined;
        }
        this.atualizar.emit();
    }

    async moverEsquerda() {
        this.setBounds(+this.grupo.start_x - 1, this.grupo.start_y, +this.grupo.end_x - 1, this.grupo.end_y);

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async moverDireita() {
        this.setBounds(+this.grupo.start_x + 1, this.grupo.start_y, +this.grupo.end_x + 1, this.grupo.end_y);

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async moverBaixo() {
        this.setBounds(this.grupo.start_x, +this.grupo.start_y + 1, this.grupo.end_x, +this.grupo.end_y + 1);

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async moverCima() {
        this.setBounds(this.grupo.start_x, +this.grupo.start_y - 1, this.grupo.end_x, +this.grupo.end_y - 1);

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    aumentarGrupo() {
        if (this.grupo.autoSize) {
            return;
        }
        this.setBounds(this.grupo.start_x, this.grupo.start_y, +this.grupo.end_x + 1, this.grupo.end_y);

        this.atualizar.emit();
    }

    diminuirGrupo() {
        if (this.grupo.autoSize) {
            return;
        }
        this.setBounds(this.grupo.start_x, this.grupo.start_y, +this.grupo.end_x - 1, this.grupo.end_y);

        this.atualizar.emit();
    }

    aumentarAltura() {
        if (this.grupo.autoSize) {
            return;
        }
        this.setBounds(this.grupo.start_x, this.grupo.start_y, this.grupo.end_x, +this.grupo.end_y + 1);

        this.atualizar.emit();
    }

    diminuirAltura() {
        if (this.grupo.autoSize) {
            return;
        }
        this.setBounds(this.grupo.start_x, this.grupo.start_y, this.grupo.end_x, +this.grupo.end_y - 1);

        this.atualizar.emit();
    }

    replicarGrupo() {
        this.replicar.emit(this.grupo);
    }


    async alinharDireita() {
        // vamos calcular a primeira posição inválida horizontalmente
        const boxes = this.calculator.calcBoxes(this.gruposRef);

        let contFrente = 0;

        const endX = +this.grupo.end_x + 1;
        // pra frente
        for (let i = endX; i < this.painel.dimension_x; i++) {
            let livre = true;

            for (let j = this.grupo.start_y; j <= this.grupo.end_y; j++) {
                if (!this.calculator.estaLivre(boxes, i, j)) {
                    livre = false;
                    break;
                }
            }

            if (livre) {
                contFrente++;
            } else {
                break;
            }
        }

        this.grupo.start_x = +this.grupo.start_x + contFrente;
        this.grupo.end_x = +this.grupo.end_x + contFrente;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async alinharEsquerda() {
        // vamos calcular a primeira posição inválida horizontalmente pra trás
        const boxes = this.calculator.calcBoxes(this.gruposRef);

        let contTras = 0;

        const startX = this.grupo.start_x - 1;
        // pra tras
        for (let i = startX; i >= 0; i--) {
            let livre = true;

            for (let j = this.grupo.start_y; j <= this.grupo.end_y; j++) {
                if (!this.calculator.estaLivre(boxes, i, j)) {
                    livre = false;
                    break;
                }
            }

            if (livre) {
                contTras++;
            } else {
                break;
            }
        }

        this.grupo.start_x = +this.grupo.start_x - contTras;
        this.grupo.end_x = +this.grupo.end_x - contTras;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async alinharBaixo() {
        const boxes = this.calculator.calcBoxes(this.gruposRef);

        let contBaixo = 0;

        const endY = +this.grupo.end_y + 1;
        // pra baixo
        for (let i = endY; i < this.painel.dimension_y; i++) {
            let livre = true;

            for (let j = this.grupo.start_x; j <= this.grupo.end_x; j++) {
                if (!this.calculator.estaLivre(boxes, j, i)) {
                    livre = false;
                    break;
                }
            }

            if (livre) {
                contBaixo++;
            } else {
                break;
            }
        }

        this.grupo.start_y = +this.grupo.start_y + contBaixo;
        this.grupo.end_y = +this.grupo.end_y + contBaixo;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async alinharCima() {
        const boxes = this.calculator.calcBoxes(this.gruposRef);

        let contCima = 0;

        const startY = this.grupo.start_y - 1;
        // pra cima
        for (let i = startY; i >= 0; i--) {
            let livre = true;

            for (let j = this.grupo.start_x; j <= this.grupo.end_x; j++) {
                if (!this.calculator.estaLivre(boxes, j, i)) {
                    livre = false;
                    break;
                }
            }

            if (livre) {
                contCima++;
            } else {
                break;
            }
        }

        this.grupo.start_y = +this.grupo.start_y - contCima;
        this.grupo.end_y = +this.grupo.end_y - contCima;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    async justificarHorizontal() {
        // a partir do fim e do começo do grupo que estamos alinhando
        // vamos calcular a primeira posição inválida horizontalmente
        // e vamos fazer metade de quantas iterações passaram
        const boxes = this.calculator.calcBoxes(this.gruposRef);

        let contFrente = 0;
        let contTras = 0;

        const endX = +this.grupo.end_x + 1;
        // pra frente
        for (let i = endX; i < this.painel.dimension_x; i++) {
            let livre = true;

            for (let j = this.grupo.start_y; j <= this.grupo.end_y; j++) {
                if (!this.calculator.estaLivre(boxes, i, j)) {
                    livre = false;
                    break;
                }
            }

            if (livre) {
                contFrente++;
            } else {
                break;
            }
        }

        const startX = this.grupo.start_x - 1;
        // pra tras
        for (let i = startX; i >= 0; i--) {
            let livre = true;

            for (let j = this.grupo.start_y; j <= this.grupo.end_y; j++) {
                if (!this.calculator.estaLivre(boxes, i, j)) {
                    livre = false;
                    break;
                }
            }

            if (livre) {
                contTras++;
            } else {
                break;
            }
        }

        const offsetLeft = Math.ceil((contFrente + contTras) / 2) - contTras;
        const offsetRight = Math.floor((contFrente + contTras) / 2) - contFrente;

        this.grupo.start_x = +this.grupo.start_x + offsetLeft;
        this.grupo.end_x = +this.grupo.end_x - offsetRight;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }
    async justificarVertical() {
        const boxes = this.calculator.calcBoxes(this.gruposRef);

        let contBaixo = 0;
        let contCima = 0;

        const endY = +this.grupo.end_y + 1;
        // pra baixo
        for (let i = endY; i < this.painel.dimension_y; i++) {
            let livre = true;

            for (let j = this.grupo.start_x; j <= this.grupo.end_x; j++) {
                if (!this.calculator.estaLivre(boxes, j, i)) {
                    livre = false;
                    break;
                }
            }

            if (livre) {
                contBaixo++;
            } else {
                break;
            }
        }

        const startY = this.grupo.start_y - 1;
        // pra cima
        for (let i = startY; i >= 0; i--) {
            let livre = true;

            for (let j = this.grupo.start_x; j <= this.grupo.end_x; j++) {
                if (!this.calculator.estaLivre(boxes, j, i)) {
                    livre = false;
                    break;
                }
            }

            if (livre) {
                contCima++;
            } else {
                break;
            }
        }

        const offsetTop = Math.ceil((contBaixo + contCima) / 2) - contCima;
        const offsetBottom = Math.floor((contBaixo + contCima) / 2) - contBaixo;

        this.grupo.start_y = +this.grupo.start_y + offsetTop;
        this.grupo.end_y = +this.grupo.end_y - offsetBottom;

        await this.shouldAutoSize();

        this.atualizar.emit();
    }

    filtrarFontesComCaracteres(fontes: model.Fonte[]): model.Fonte[] {
        const caracteres = new Set(this.grupo.content.replace(/\s+/g, '').split(''));

        return this.filterFontsWithCaracters(fontes, caracteres);
    }

    filtrarFontesComVariaveis(fontes: model.Fonte[]): model.Fonte[] {
        let variavelAtiva = this.infoVariavel(this.grupo.idOrigem);
        let variableCharacter = this.variablesCharacters.find((item) => item.variableId === variavelAtiva.tipoVariavel);
        let caracteres = new Set(variableCharacter.possibleCharacters.split(''));

        return this.filterFontsWithCaracters(fontes, caracteres);
    }

    filterFontsWithCaracters (fontes:model.Fonte[], caracteres: Set<string>): model.Fonte[] {
        return fontes.filter((fonte) => {
            let caracteresOfFonte = new Set(fonte.letras.map((letra) => letra.caractere));
            return this.isSuperset(caracteresOfFonte, caracteres);
        })
    }

    isSuperset(set: Set<string>, subset: Set<string>): boolean {
        for (const elem of subset) {
            if (!set.has(elem)) {
                return false;
            }
        }
        return true;
    }

    filtrarTipoVariavelPorFonte (idVariavel: number): boolean {
        let variableCharacter = this.variablesCharacters.find((item) => item.variableId === idVariavel);
        let caracteres = new Set(variableCharacter.possibleCharacters.split(''));
        let variavelAtiva = this.infoVariavel(this.grupo.idOrigem);
        let caracteresOfFonte = new Set(variavelAtiva.fonte.letras.map((letra) => letra.caractere));

        return this.isSuperset(caracteresOfFonte, caracteres);
    }

}
