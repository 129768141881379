import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackendMessagesService {
    constructor(
    ){}

    public from_code(code: string) {
        return this.messages[code];
    }

    //////////////////////////////////////////////////////////////////////////
    ///                      !Atenção!                                     ///
    /// Ao editar essa lista, lembre-se de editar o arquivo `API/i18n.php` ///
    //////////////////////////////////////////////////////////////////////////
    public messages = {

        BLOCKED: $localize `BLOCKED`,
        BLOCKED_ACCESS: $localize `BLOCKED_ACCESS`,
        CANNOT_DELETE_CHARACTER_IN_USE: $localize `CANNOT_DELETE_CHARACTER_IN_USE`,
        CANNOT_DELETE_FONT_IN_USE: $localize `CANNOT_DELETE_FONT_IN_USE`,
        CANNOT_DELETE_RESOURCE_IN_USE: $localize `CANNOT_DELETE_RESOURCE_IN_USE`,
        CANNOT_DISABLE_PANEL_RGB_WHILE_IN_USE: $localize `CANNOT_DISABLE_PANEL_RGB_WHILE_IN_USE`,
        COMPANY_NOT_FOUND: $localize `COMPANY_NOT_FOUND`,
        CONFLICT_IN_GROUP_POSITIONING: $localize `CONFLICT_IN_GROUP_POSITIONING`,
        CONTACT_NOT_FOUND: $localize `CONTACT_NOT_FOUND`,
        DESTINATION_NOT_FOUND: $localize `DESTINATION_NOT_FOUND`,
        DRAWING_NOT_FOUND: $localize `DRAWING_NOT_FOUND`,
        ERROR_SENDING_MAIL: $localize `ERROR_SENDING_MAIL`,
        E_MAIL_BODY_NOT_FOUND: $localize `E_MAIL_BODY_NOT_FOUND`,
        IMPOSSIBLE_TO_BLOCK_THIS_COMPANY: $localize `IMPOSSIBLE_TO_BLOCK_THIS_COMPANY`,
        IMPOSSIBLE_TO_DELETE_THIS_COMPANY: $localize `IMPOSSIBLE_TO_DELETE_THIS_COMPANY`,
        IMPOSSIBLE_TO_DELETE_THIS_USER: $localize `IMPOSSIBLE_TO_DELETE_THIS_USER`,
        INCORRECT_CANVAS_HEIGHT: $localize `INCORRECT_CANVAS_HEIGHT`,
        INCORRECT_CANVAS_WIDTH: $localize `INCORRECT_CANVAS_WIDTH`,
        INCORRECT_CAN_ADDRESS: $localize `INCORRECT_CAN_ADDRESS`,
        INCORRECT_CHARACTER_COUNT: $localize `INCORRECT_CHARACTER_COUNT`,
        INCORRECT_COLOR: $localize `INCORRECT_COLOR`,
        INCORRECT_COMPANY_CODE: $localize `INCORRECT_COMPANY_CODE`,
        INCORRECT_COMPANY_NAME: $localize `INCORRECT_COMPANY_NAME`,
        INCORRECT_CONTACT_NAME: $localize `INCORRECT_CONTACT_NAME`,
        INCORRECT_CREDENTIALS: $localize `INCORRECT_CREDENTIALS`,
        INCORRECT_DESCRIPTION: $localize `INCORRECT_DESCRIPTION`,
        INCORRECT_DESTINATION_CODE: $localize `INCORRECT_DESTINATION_CODE`,
        INCORRECT_DESTINATION_COUNT: $localize `INCORRECT_DESTINATION_COUNT`,
        INCORRECT_DESTINATION_NAME: $localize `INCORRECT_DESTINATION_NAME`,
        INCORRECT_EFFECT: $localize `INCORRECT_EFFECT`,
        INCORRECT_EMAIL: $localize `INCORRECT_EMAIL`,
        INCORRECT_GROUP_SPACING: $localize `INCORRECT_GROUP_SPACING`,
        INCORRECT_GROUP_X_END: $localize `INCORRECT_GROUP_X_END`,
        INCORRECT_GROUP_X_OFFSET: $localize `INCORRECT_GROUP_X_OFFSET`,
        INCORRECT_GROUP_X_START: $localize `INCORRECT_GROUP_X_START`,
        INCORRECT_GROUP_Y_END: $localize `INCORRECT_GROUP_Y_END`,
        INCORRECT_GROUP_Y_OFFSET: $localize `INCORRECT_GROUP_Y_OFFSET`,
        INCORRECT_GROUP_Y_START: $localize `INCORRECT_GROUP_Y_START`,
        INCORRECT_MESSAGE_DURATION: $localize `INCORRECT_MESSAGE_DURATION`,
        INCORRECT_MESSAGE_ORDER_SIZE: $localize `INCORRECT_MESSAGE_ORDER_SIZE`,
        INCORRECT_NAME: $localize `INCORRECT_NAME`,
        INCORRECT_ORIGIN: $localize `INCORRECT_ORIGIN`,
        INCORRECT_PANEL_DIMENSIONS: $localize `INCORRECT_PANEL_DIMENSIONS`,
        INCORRECT_PANEL_LOCATION: $localize `INCORRECT_PANEL_LOCATION`,
        INCORRECT_PHONE_NUMBER: $localize `INCORRECT_PHONE_NUMBER`,
        INCORRECT_PROJECT_DESCRIPTION: $localize `INCORRECT_PROJECT_DESCRIPTION`,
        INCORRECT_PROJECT_NAME: $localize `INCORRECT_PROJECT_NAME`,
        INCORRECT_TOKEN: $localize `INCORRECT_TOKEN`,
        INVALID_ADMIN: $localize `INVALID_ADMIN`,
        INVALID_CHAR_USED: $localize `INVALID_CHAR_USED`,
        INVALID_EMAIL: $localize `INVALID_EMAIL`,
        INVALID_LANGUAGE_CODE: $localize `INVALID_LANGUAGE_CODE`,
        INVALID_NAME: $localize `INVALID_NAME`,
        INVALID_NEW_PASSWORD: $localize `INVALID_NEW_PASSWORD`,
        INVALID_OLD_PASSWORD: $localize `INVALID_OLD_PASSWORD`,
        INVALID_PASSWORD: $localize `INVALID_PASSWORD`,
        INVALID_PHONE_NUMBER: $localize `INVALID_PHONE_NUMBER`,
        INVALID_TOKEN: $localize `INVALID_TOKEN`,
        MESSAGE_GROUP_LIMIT_REACHED: $localize `MESSAGE_GROUP_LIMIT_REACHED`,
        MESSAGE_NOT_FOUND: $localize `MESSAGE_NOT_FOUND`,
        NEEDS_ELEVATION: $localize `NEEDS_ELEVATION`,
        NOT_IMPLEMENTED: $localize `NOT_IMPLEMENTED`,
        NO_ACCESS_TOKEN_PROVIDED: $localize `NO_ACCESS_TOKEN_PROVIDED`,
        NO_USER_WITH_THIS_EMAIL: $localize `NO_USER_WITH_THIS_EMAIL`,
        PANEL_MESSAGE_LIMIT_REACHED: $localize `PANEL_MESSAGE_LIMIT_REACHED`,
        PANEL_NOT_FOUND: $localize `PANEL_NOT_FOUND`,
        PROJECT_NOT_FOUND: $localize `PROJECT_NOT_FOUND`,
        REPEATED_CAN_ADDRESS: $localize `REPEATED_CAN_ADDRESS`,
        RESTRICTED_ACCESS: $localize `RESTRICTED_ACCESS`,
        ROUTE_NOT_FOUND: $localize `ROUTE_NOT_FOUND`,
        TOKEN_EXPIRED: $localize `TOKEN_EXPIRED`,
        TOKEN_REFRESH: $localize `TOKEN_REFRESH`,
        UNABLE_TO_DUPLICATE_PROJECT: $localize `UNABLE_TO_DUPLICATE_PROJECT`,
        UNABLE_TO_SAVE_COMPANY: $localize `UNABLE_TO_SAVE_COMPANY`,
        UNABLE_TO_SAVE_THE_CONTACT: $localize `UNABLE_TO_SAVE_THE_CONTACT`,
        UNABLE_TO_SAVE_THE_FONT: $localize `UNABLE_TO_SAVE_THE_FONT`,
        UNABLE_TO_SAVE_THE_PROJECT: $localize `UNABLE_TO_SAVE_THE_PROJECT`,
        UNABLE_TO_SAVE_THE_RESOURCE: $localize `UNABLE_TO_SAVE_THE_RESOURCE`,
        UNABLE_TO_SAVE_USER: $localize `UNABLE_TO_SAVE_USER`,
        UNABLE_TO_UPDATE_COMPANY: $localize `UNABLE_TO_UPDATE_COMPANY`,
        UNABLE_TO_UPDATE_USER: $localize `UNABLE_TO_UPDATE_USER`,
        UNKNOWN_ADMIN: $localize `UNKNOWN_ADMIN`,
        USER_LIMIT_REACHED: $localize `USER_LIMIT_REACHED`,
        USER_NOT_FOUND: $localize `USER_NOT_FOUND`,
        WRONG_OLD_PASSWORD: $localize `WRONG_OLD_PASSWORD`,
        OUTDATED_CLIENT_CODE: $localize `OUTDATED_CLIENT_CODE`,

    }
}
